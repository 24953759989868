export const useInputProps = {
    modelValue: null,
    modelModifiers: { default: () => ({}) },
    id: {
        type: String,
        required: true,
    },
    placeholder: {
        type: String,
        default: '',
    },
    inputMode: {
        type: String,
        default: 'text',
    },
    lazy: {
        type: Boolean,
        default: false,
    },
    name: String,
    disabled: Boolean,
    required: Boolean,
};

export const useLabelProps = {
    id: String,
    label: String,
    flag: String,
};

export const useErrorProps = {
    error: {
        type: [String, null],
        default: null,
    },
    transition: {
        type: String,
        default: 'error-fade',
    },
};

export function useFieldHandlers(props, emit) {
    const handleInput = (event) => {
        let inputValue = event.target.value;
        if (props.modelModifiers.capitalize) {
            inputValue = inputValue.toUpperCase();
        }
        event.target.setCustomValidity('');
        if (!props.modelModifiers.lazy) {
            emit('update:modelValue', inputValue);
        }
        emit('update:error', null);
        emit('input', event);
    };

    const handleChange = (event) => {
        let inputValue = event.target.value;
        if (props.modelModifiers.capitalize) {
            inputValue = inputValue.toUpperCase();
        }
        if (props.modelModifiers.lazy) {
            emit('update:modelValue', inputValue);
        }
        emit('change', event);
    };
    return {
        handleInput,
        handleChange,
    };
}
